import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  maxLength,
  required,
  composeValidators,
  autocompleteSearchRequired,
  autocompletePlaceSelected,
} from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldSelect,
  FieldCheckbox,
  FieldPhoneNumberInput,
  LocationAutocompleteInputField,
} from '../../components';
import config from '../../config';
import hand from './helping-hand-alert-transparent.png';
import css from './EditListingDescriptionForm.module.css';
import genderOptions from './SelfIDOptions.js';

const TITLE_MAX_LENGTH = 60;
const identity = v => v;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        category,
        userTypes,
        values,
        emailVerified,
      } = formRenderProps;

      const titleMessage =
        category == 'requirement'
          ? intl.formatMessage({ id: 'EditListingDescriptionForm.deafTitle' })
          : intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage =
        category == 'requirement'
          ? intl.formatMessage({
              id: 'EditListingDescriptionForm.deafTitlePlaceholder',
            })
          : intl.formatMessage({
              id: 'EditListingDescriptionForm.titlePlaceholder',
            });
      const titleRequiredMessage =
        category == 'requirement'
          ? intl.formatMessage({
              id: 'EditListingDescriptionForm.deafTitleRequired',
            })
          : intl.formatMessage({
              id: 'EditListingDescriptionForm.titleRequired',
            });

      const deafContractDepartmentTitle = intl.formatMessage({
        id: 'EditListingDescriptionForm.deafCompanyOrOrganizationTitle',
      });
      const deafContractDepartmentPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.deafCompanyOrOrganizationPlaceholder',
      });
      const deafContractDepartmentRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.deafCompanyOrOrganizationRequiredMessage',
      });

      const telephoneNumberRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.telRequiredMessage',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const descriptionMessage =
        category == 'requirement'
          ? intl.formatMessage({
              id: 'EditListingDescriptionForm.deafDescriptionTitle',
            })
          : intl.formatMessage({
              id: 'EditListingDescriptionForm.description',
            });
      const descriptionPlaceholderMessage =
        category == 'requirement'
          ? intl.formatMessage({
              id: 'EditListingDescriptionForm.deafDescriptionPlaceholder',
            })
          : intl.formatMessage({
              id: 'EditListingDescriptionForm.descriptionPlaceholder',
            });
      const descriptionRequiredMessage =
        category == 'requirement'
          ? intl.formatMessage({
              id: 'EditListingDescriptionForm.deafDescriptionRequired',
            })
          : intl.formatMessage({
              id: 'EditListingDescriptionForm.descriptionRequired',
            });
      const editBookingMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.editBookingMessage',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const formDisabledVerifyEmail = !emailVerified ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.verifyEmail" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || !emailVerified;

      const myEthnicityOptions = findOptionsForSelectFilter('myEthnicity', filterConfig);
      const ethnicityCheckOptions = [{ key: 'ethnicityCheck', label: '' }];
      const genderLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.genderLabel',
      });
      const ethnicityLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.ethnicityLabel',
      });
      const ethnicityConsentLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.ethnicityConsentLabel',
      });
      const correctEmailLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.correctEmail',
      });
      const autoFocusMaybe = category == 'requirement' ? {} : { autoFocus: true };
      const addressPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.addressRequired',
      });
      const addressNotRecognisedMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.addressNotRecognised',
      });

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            readOnly={userTypes.includes('contractCustomer')}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            {...autoFocusMaybe}
          />

          {category != 'requirement' && (
            <>
              <div className={classNames(css.sectionContainer)}>
                <FieldSelect
                  className={css.features}
                  id="myGender"
                  name="myGender"
                  label={genderLabel}
                  intl={intl}
                  options={genderOptions}
                >
                  <option disabled value="">
                    Choose from the following...{' '}
                  </option>
                  <option value="I prefer not to say">I prefer not to say</option>
                  {genderOptions.map(c => (
                    <option key={c.key} value={c.key}>
                      {c.label}
                    </option>
                  ))}
                </FieldSelect>

                <p className={css.formInputHelperInfo}>
                  <FormattedMessage id="EditListingDescriptionForm.genderInfo" />
                </p>
              </div>

              <div className={classNames(css.sectionContainer)}>
                <FieldSelect
                  className={css.features}
                  type="select"
                  id="myEthnicity"
                  name="myEthnicity"
                  label={ethnicityLabel}
                  options={myEthnicityOptions}
                  intl={intl}
                >
                  <option disabled value="">
                    Choose from the following...{' '}
                  </option>
                  <option value="I prefer not to say">I prefer not to say</option>
                  {myEthnicityOptions.map(c => (
                    <option key={c.key} value={c.key}>
                      {c.label}
                    </option>
                  ))}
                </FieldSelect>
                <p className={css.formInputHelperInfo}>
                  <FormattedMessage id="EditListingDescriptionForm.ethnicityInfo" />
                </p>
                <FieldCheckbox
                  className={css.features}
                  id="ethnicityConsent"
                  name="ethnicityConsent"
                  label={ethnicityConsentLabel}
                  value="true"
                />
              </div>
              <FieldTextInput
                id="description"
                name="description"
                className={css.features}
                type="textarea"
                label={descriptionMessage}
                placeholder={descriptionPlaceholderMessage}
                validate={composeValidators(required(descriptionRequiredMessage))}
              />
              <p className={css.formInputHelperInfo}>
                <FormattedMessage id="EditListingDescriptionForm.descriptionInfo" />
              </p>
            </>
          )}
          {userTypes.includes('contractCustomer') ? (
            <FieldTextInput
              id="email"
              name="email"
              className={css.title}
              type="text"
              label={correctEmailLabel}
              readOnly={true}
            />
          ) : null}
          <div>
            {category == 'requirement' && (
              <div>
                <div>
                  <FieldPhoneNumberInput
                    id="tel"
                    name="tel"
                    className={css.title}
                    label="Telephone number"
                    validate={composeValidators(required(telephoneNumberRequiredMessage))}
                  />

                  {userTypes.includes('contractCustomer') ? (
                    <FieldTextInput
                      id="secondaryEmail"
                      name="secondaryEmail"
                      className={css.title}
                      type="text"
                      label={'Alternate notification email (optional)'}
                    />
                  ) : (
                    <FieldCheckbox
                      className={css.features}
                      id="telImport"
                      name="telImport"
                      label={
                        'Save this number for next time. This will also update your number in your account settings.'
                      }
                      value="true"
                    />
                  )}
                </div>
                <div className={css.field}>
                  <FieldTextInput
                    id="addressDepartment"
                    name="addressDepartment"
                    className={css.description}
                    type="text"
                    readOnly={userTypes.includes('contractCustomer')}
                    label={deafContractDepartmentTitle}
                    placeholder={deafContractDepartmentPlaceholder}
                    validate={composeValidators(required(deafContractDepartmentRequiredMessage))}
                  />
                  <div className={css.description}>
                    {userTypes.includes('contractCustomer') ? (
                      <FieldTextInput
                        name="invoicingAddress.search"
                        id="invoicingAddress.search"
                        className={css.description}
                        type="text"
                        label={descriptionMessage}
                        disabled={true}
                      />
                    ) : (
                      <LocationAutocompleteInputField
                        name="invoicingAddress"
                        id="invoicingAddress"
                        useDefaultPredictions={false}
                        format={identity}
                        valueFromForm={values.invoicingAddress}
                        isTopBarSearchForm={true}
                        label={descriptionMessage}
                        placeholder={addressPlaceholderMessage}
                        validate={composeValidators(
                          autocompleteSearchRequired(addressRequiredMessage),
                          autocompletePlaceSelected(addressNotRecognisedMessage)
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          {formDisabledVerifyEmail}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {category == 'requirement'
              ? saveActionMsg.toLowerCase().startsWith('save')
                ? 'Save'
                : "Next: Deaf person's details"
              : saveActionMsg}
          </Button>
          {category == 'requirement' && !userTypes.includes('contractCustomer') ? (
            <div className={css.editBookingAlert}>
              <img src={hand} alt="Signalise helping hand alert" className={css.hand}></img>
              <p>{editBookingMessage}</p>
            </div>
          ) : null}
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  myEthnicityOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  ethnicityConsent: string,
  additionalQualificationsOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
