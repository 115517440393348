import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
  isValidTime,
  required,
} from '../../util/validators';
import {
  Form,
  FieldSelect,
  FieldReactSelect,
  LocationAutocompleteInputField,
  FieldAirbnbDateTimePicker,
  Button,
  NamedLink,
  FieldTextInput,
} from '../../components';

import hand from './helping-hand-wink-transparent.png';
import moment from 'moment';

import css from './EditListingLocationForm.module.css';

const identity = v => v;

export const EditListingLocationFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        category,
        userTypes,
        locations,
        departments,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
      } = formRenderProps;

      const optionalText = intl.formatMessage({
        id: 'EditListingLocationForm.optionalText',
      });

      const titleRequiredMessage = intl.formatMessage({ id: 'EditListingLocationForm.address' });
      const bookingLocationMessage = intl.formatMessage({
        id: 'EditListingLocationForm.bookingLocationAddress',
      });

      const addressPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
      });
      const addressNotRecognisedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognised',
      });
      // person or remote
      const personOrRemoteLabel = intl.formatMessage({
        id: 'EditListingDetailsForm.personOrRemoteLabel',
      });
      const remoteBookingInfo = intl.formatMessage({
        id: 'EditListingDetailsForm.remoteBookingInfo',
      });
      const bookingDeptLabel = intl.formatMessage({
        id: 'EditListingLocationForm.bookingDeptLabel',
      });
      const bookingDeptPlaceholder = intl.formatMessage({
        id: 'EditListingLocationForm.bookingDeptPlaceholder',
      });
      const locationNameLabel = intl.formatMessage({
        id: 'EditListingLocationForm.locationNameLabel',
      });
      const locationNamePlaceholder = intl.formatMessage({
        id: 'EditListingLocationForm.locationNamePlaceholder',
      });
      const subLocationNameLabel = intl.formatMessage({
        id: 'EditListingLocationForm.subLocationNameLabel',
      });
      const subLocationNamePlaceholder = intl.formatMessage({
        id: 'EditListingLocationForm.subLocationNamePlaceholder',
      });
      const contactLabel = intl.formatMessage(
        { id: 'EditListingLocationForm.contactLabel' },
        { optionalText: optionalText }
      );
      const bookingIsRecordedLabel = intl.formatMessage({
        id: 'EditListingDetailsForm.bookingIsRecordedLabel',
      });
      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.showListingFailed" />
        </p>
      ) : null;
      const addCustomField = options => {
        options.unshift({
          key: `I can't find my subdepartment`,
          value: `I can't find my subdepartment`,
          label: `I can't find my subdepartment`,
        });
        return options;
      };
      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          {category === 'requirement' && (
            <div
              className={css.field}
              style={
                userTypes.includes('contractCustomer') ? { display: 'none' } : { display: 'block' }
              }
            >
              <FieldSelect
                id="personOrRemote"
                name="personOrRemote"
                label={personOrRemoteLabel}
                autoFocus
                validate={required('This field is required')}
                defaultValue={'In person'}
              >
                <option disabled value="">
                  Choose either...
                </option>
                <option value="In person">Come to a location</option>
                <option value="Remote">Join a meeting online</option>
              </FieldSelect>
            </div>
          )}
          {values?.personOrRemote == 'Remote' && (
            <div>
              <div className={css.bookingInfoHelp}>
                <img src={hand} alt="Signalise helping hand alert" className={css.hand}></img>
                <p>{remoteBookingInfo}</p>
              </div>
              <FieldSelect
                id="isRecorded"
                name="isRecorded"
                label={bookingIsRecordedLabel}
                value="isRecorded"
                defaultValue={`Don't know`}
              >
                <option disabled value="">
                  Choose either...
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Don't know">Don't know</option>
              </FieldSelect>
            </div>
          )}

          {values?.personOrRemote == 'In person' && (
            <div className={css.sectionContainer}>
              <h2 className={css.sectionTitle}>
                <FormattedMessage id="EditListingLocationForm.locationTitleRequirement" />
              </h2>
              <p className={css.explanatoryText}>
                <FormattedMessage id="EditListingLocationForm.explanatoryTextRequirement" />
              </p>
              <div className={css.bookingInfoHelp}>
                <img src={hand} alt="Signalise helping hand alert" className={css.hand}></img>
                <p>
                  <strong>Tip:</strong> If you cannot find your location this can be manually
                  entered by selecting "I can't find the location". You can also enter more details
                  in the first field below.
                </p>
              </div>
              {userTypes.includes('contractCustomer') && (
                <div className={css.field}>
                  <FieldTextInput
                    className={css.building}
                    type="textarea"
                    name="contact"
                    id="contact"
                    label={contactLabel}
                    placeholder={
                      'Tell us the who to contact or where to go e.g. report to main reception'
                    }
                  />{' '}
                </div>
              )}
              <div className={css.building}>
                {!(
                  userTypes.includes('liverpoolPlace' || 'liverpoolCCG') ||
                  userTypes.includes('knowsleyPlace' || 'knowsleyCCG') ||
                  userTypes.includes('southportFormbyPlace') ||
                  userTypes.includes('seftonPlace' || 'seftonSFCCG')
                ) ? (
                  departments ? (
                    <FieldReactSelect
                      className={css.building}
                      id="bookingDepartment"
                      name="bookingDepartment"
                      options={departments}
                      autoComplete="bookingDepartment"
                      label={bookingDeptLabel}
                      placeholder={'Choose from the options'}
                      isSearchable={true}
                      validate={required('This field is required')}
                    />
                  ) : (
                    <FieldTextInput
                      className={css.building}
                      type="textarea"
                      name="bookingDepartment"
                      id="bookingDepartment"
                      label={bookingDeptLabel}
                      placeholder={bookingDeptPlaceholder}
                    />
                  )
                ) : null}
              </div>
              {locations ? (
                <div className={css.building}>
                  {' '}
                  <FieldReactSelect
                    className={css.field}
                    id="locationShort"
                    name="locationShort"
                    options={locations.map(c => {
                      return { key: c.name, value: c.address, label: c.name };
                    })}
                    label={'Select booking location'}
                    placeholder={'Choose from the options'}
                    validate={required('This field is required')}
                  />
                  {values?.locationShort?.value == 'address' && (
                    <FieldTextInput
                      className={css.locationAddress}
                      type="text"
                      name="locationName"
                      id="locationName"
                      label={locationNameLabel}
                      placeholder={locationNamePlaceholder}
                    />
                  )}
                  {values?.locationShort?.value == 'address' && (
                    <LocationAutocompleteInputField
                      className={css.locationAddress}
                      inputClassName={css.locationAutocompleteInput}
                      iconClassName={css.locationAutocompleteInputIcon}
                      predictionsClassName={css.predictionsRoot}
                      validClassName={css.validLocation}
                      name="location"
                      label={bookingLocationMessage}
                      placeholder={addressPlaceholderMessage}
                      useDefaultPredictions={false}
                      format={identity}
                      valueFromForm={values.location}
                      validate={composeValidators(
                        autocompleteSearchRequired(addressRequiredMessage),
                        autocompletePlaceSelected(addressNotRecognisedMessage)
                      )}
                    />
                  )}
                  {locations.find(a => a.address == values?.locationShort?.value)?.subLocs ? (
                    <FieldReactSelect
                      className={css.field}
                      id="subLocation"
                      name="subLocation"
                      options={addCustomField(
                        locations
                          .find(a => a.address == values?.locationShort.value)
                          .data.subLocations.map(c => {
                            return { key: c, value: c, label: c };
                          })
                      )}
                      label={'Select your department'}
                      placeholder={'Choose from the options'}
                      validate={required('This field is required')}
                    />
                  ) : null}
                  {values?.subLocation?.value == `I can't find my subdepartment` && (
                    <FieldTextInput
                      className={css.locationAddress}
                      type="text"
                      name="subLocationName"
                      id="sublocationName"
                      label={subLocationNameLabel}
                      placeholder={subLocationNamePlaceholder}
                    />
                  )}
                </div>
              ) : (
                <div>
                  <FieldTextInput
                    className={css.locationAddress}
                    type="text"
                    name="locationName"
                    id="locationName"
                    label={locationNameLabel}
                    placeholder={locationNamePlaceholder}
                  />
                  <LocationAutocompleteInputField
                    className={css.locationAddress}
                    inputClassName={css.locationAutocompleteInput}
                    iconClassName={css.locationAutocompleteInputIcon}
                    predictionsClassName={css.predictionsRoot}
                    validClassName={css.validLocation}
                    name="location"
                    label={bookingLocationMessage}
                    placeholder={addressPlaceholderMessage}
                    useDefaultPredictions={false}
                    format={identity}
                    valueFromForm={values.location}
                    validate={composeValidators(
                      autocompleteSearchRequired(addressRequiredMessage),
                      autocompletePlaceSelected(addressNotRecognisedMessage)
                    )}
                  />
                </div>
              )}
            </div>
          )}
          {category !== 'requirement' && (
            <div className={css.sectionContainer}>
              <h2 className={css.sectionTitle}>
                <FormattedMessage id="EditListingLocationForm.locationTitle" />
              </h2>
              <p className={css.explanatoryText}>
                <FormattedMessage id="EditListingLocationForm.explanatoryText" />
              </p>
              <LocationAutocompleteInputField
                className={css.locationAddress}
                inputClassName={css.locationAutocompleteInput}
                iconClassName={css.locationAutocompleteInputIcon}
                predictionsClassName={css.predictionsRoot}
                validClassName={css.validLocation}
                name="location"
                label={titleRequiredMessage}
                placeholder={addressPlaceholderMessage}
                useDefaultPredictions={false}
                format={identity}
                valueFromForm={values.location}
                validate={composeValidators(
                  autocompleteSearchRequired(addressRequiredMessage),
                  autocompletePlaceSelected(addressNotRecognisedMessage)
                )}
              />
              <p className={css.explanatoryText}>
                <strong>
                  <FormattedMessage id="EditListingLocationForm.locationHidden" />
                </strong>
              </p>
            </div>
          )}
          {category === 'requirement' && (
            <p className={css.dateMessage}>
              If a booking is in less than 24 hours please call the bookings team on{' '}
              <a href="tel:01518080373">0151 808 0373</a>
            </p>
          )}
          {category === 'requirement' && (
            <div className={css.dateContainer}>
              {' '}
              <div className={css.datePicker}>
                <FieldAirbnbDateTimePicker
                  name="requirementStart"
                  id="requirementStart"
                  label="When does the booking start?"
                  validate={composeValidators(
                    required('Please select a booking start date and time'),
                    isValidTime('Selected time is not valid', values, 'start')
                  )}
                  minDate={moment(new Date())
                    .startOf('hour')
                    .toISOString()}
                />
              </div>
              <div className={css.datePicker}>
                <FieldAirbnbDateTimePicker
                  name="requirementEnd"
                  id="requirementEnd"
                  label="When does the booking end?"
                  validate={composeValidators(
                    required('Please select a booking end date and time'),
                    isValidTime('Selected time is not valid', values, 'end')
                  )}
                  minDate={moment(new Date())
                    .startOf('hour')
                    .toISOString()}
                />
              </div>
            </div>
          )}
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={category !== 'requirement' && submitDisabled}
            ready={submitReady}
          >
            {category === 'requirement'
              ? saveActionMsg.toLowerCase().startsWith('save')
                ? 'Save'
                : 'Next: Booking details'
              : saveActionMsg}
          </Button>
          {!userTypes.includes('contractCustomer') && (
            <div className={css.accountSettingsAlert}>
              <img src={hand} alt="Signalise hand winking" className={css.hand}></img>
              <div>
                <FormattedMessage id="EditListingLocationForm.updateAddressReminder" />
                <br />
                <NamedLink name="AddressDetailsPage" className={css.link}>
                  <FormattedMessage id="EditListingLocationForm.editAddressLink" />
                </NamedLink>
              </div>
            </div>
          )}
        </Form>
      );
    }}
  />
);

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingLocationFormComponent);
