import React from 'react';
import loadable from '@loadable/component';
import getPageDataLoadingAPI from './containers/pageDataLoadingAPI';
import { NotFoundPage } from './containers';
import PreviewResolverPage from './containers/PreviewResolverPage/PreviewResolverPage';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';
import VideoConferenceSuccessPage from './containers/VideoConferencePage/VideoConferencingSuccessPage';
import { LoadingPage } from './containers/LoadingPage/LoadingPage';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AboutPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ './containers/AboutPage/AboutPage'),  { fallback: <LoadingPage /> });
const CPTypesPage = loadable(() => import(/* webpackChunkName: "CPTypesPage" */ './containers/CPTypesPage/CPTypesPage'),  { fallback: <LoadingPage /> });
const AuthenticationPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ './containers/AuthenticationPage/AuthenticationPage'), { fallback: <LoadingPage /> });
const CheckoutPage = loadable(() => import(/* webpackChunkName: "CheckoutPage" */ './containers/CheckoutPage/CheckoutPage'), { fallback: <LoadingPage /> });
const CMSPage = loadable(() => import(/* webpackChunkName: "CMSPage" */ './containers/CMSPage/CMSPage'));
const ContactDetailsPage = loadable(() => import(/* webpackChunkName: "ContactDetailsPage" */ './containers/ContactDetailsPage/ContactDetailsPage'), { fallback: <LoadingPage /> });
const ContractsInfoPage = loadable(() => import(/* webpackChunkName: "ContractsInfoPage" */ /* webpackPrefetch: true */ './containers/ContractsInfoPage/ContractsInfoPage'), { fallback: <LoadingPage /> });
const ContractsContentPage = loadable(() => import(/* webpackChunkName: "ContractsContentPage" */ './containers/ContractsContentPage/ContractsContentPage'), { fallback: <LoadingPage /> });
const DeafPage = loadable(() => import(/* webpackChunkName: "ContactDetailsPage" */ './containers/DeafPage/DeafPage'), { fallback: <LoadingPage /> });
const EditListingPage = loadable(() => import(/* webpackChunkName: "EditListingPage" */ './containers/EditListingPage/EditListingPage'), { fallback: <LoadingPage /> });
const EmailVerificationPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ './containers/EmailVerificationPage/EmailVerificationPage'), { fallback: <LoadingPage /> });
const InboxPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ './containers/InboxPage/InboxPage'), { fallback: <LoadingPage /> });
const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ './containers/LandingPage/LandingPage'), { fallback: <LoadingPage /> });
const ListingPage = loadable(() => import(/* webpackChunkName: "ListingPage" */ /* webpackPrefetch: true */ './containers/ListingPage/ListingPage'), { fallback: <LoadingPage /> });
const PasswordChangePage = loadable(() => import(/* webpackChunkName: "PasswordChangePage" */ './containers/PasswordChangePage/PasswordChangePage'), { fallback: <LoadingPage /> });
const PasswordRecoveryPage = loadable(() => import(/* webpackChunkName: "PasswordRecoveryPage" */ './containers/PasswordRecoveryPage/PasswordRecoveryPage'), { fallback: <LoadingPage /> });
const PasswordResetPage = loadable(() => import(/* webpackChunkName: "PasswordResetPage" */ './containers/PasswordResetPage/PasswordResetPage'), { fallback: <LoadingPage /> });
const PaymentMethodsPage = loadable(() => import(/* webpackChunkName: "PaymentMethodsPage" */ './containers/PaymentMethodsPage/PaymentMethodsPage'), { fallback: <LoadingPage /> });
const PrivacyPolicyPage = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ './containers/PrivacyPolicyPage/PrivacyPolicyPage'), { fallback: <LoadingPage /> });
const ProfilePage = loadable(() => import(/* webpackChunkName: "ProfilePage" */ './containers/ProfilePage/ProfilePage'), { fallback: <LoadingPage /> });
const ProfileSettingsPage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ './containers/ProfileSettingsPage/ProfileSettingsPage'), { fallback: <LoadingPage /> });
const SearchPage = loadable(() => import(/* webpackChunkName: "SearchPage" */ /* webpackPrefetch: true */  './containers/SearchPage/SearchPage'), { fallback: <LoadingPage /> });
const StripePayoutPage = loadable(() => import(/* webpackChunkName: "StripePayoutPage" */ './containers/StripePayoutPage/StripePayoutPage'), { fallback: <LoadingPage /> });
const TermsOfServicePage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ './containers/TermsOfServicePage/TermsOfServicePage'), { fallback: <LoadingPage /> });
const TransactionPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ './containers/TransactionPage/TransactionPage'), { fallback: <LoadingPage /> });
const CommProfJoinPage = loadable(() => import(/* webpackChunkName: "CommProfJoinPage" */ './containers/CommProfJoinPage/CommProfJoinPage'), { fallback: <LoadingPage /> });
const ContactPage = loadable(() => import(/* webpackChunkName: "ContactPage" */ './containers/ContactPage/ContactPage'), { fallback: <LoadingPage /> });
const DeafCommJoinPage = loadable(() => import(/* webpackChunkName: "DeafCommJoinPage" */ './containers/DeafCommJoinPage/DeafCommJoinPage'), { fallback: <LoadingPage /> });
const FAQPage = loadable(() => import(/* webpackChunkName: "FAQPage" */ './containers/FAQPage/FAQPage'), { fallback: <LoadingPage /> });
const FavouritesPage = loadable(() => import(/* webpackChunkName: "FavouritesPage" */ './containers/FavouritesPage/FavouritesPage'), { fallback: <LoadingPage /> });
const HelpPage = loadable(() => import(/* webpackChunkName: "HelpPage" */ './containers/HelpPage/HelpPage'), { fallback: <LoadingPage /> });
const InvestorsJoinPage = loadable(() => import(/* webpackChunkName: "InvestorsJoinPage" */ './containers/InvestorsJoinPage/InvestorsJoinPage'), { fallback: <LoadingPage /> });
const JoinUsPage = loadable(() => import(/* webpackChunkName: "JoinUsPage" */ './containers/JoinUsPage/JoinUsPage'), { fallback: <LoadingPage /> });
const MailchimpSignupPage = loadable(() => import(/* webpackChunkName: "MailchimpSignupPage" */ './containers/MailchimpSignupPage/MailchimpSignupPage'), { fallback: <LoadingPage /> });
const ManageListingsPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ './containers/ManageListingsPage/ManageListingsPage'), { fallback: <LoadingPage /> });
const AddressDetailsPage = loadable(() => import(/* webpackChunkName: "AddressDetailsPage" */ './containers/AddressDetailsPage/AddressDetailsPage'), { fallback: <LoadingPage /> });
const GPDetailsPage = loadable(() => import(/* webpackChunkName: "GPDetailsPage" */ './containers/GPDetailsPage/GPDetailsPage'), { fallback: <LoadingPage /> });
const VideoAboutPage = loadable(() => import(/* webpackChunkName: "VideoAboutPage" */ './containers/VideoAboutPage/VideoAboutPage'), { fallback: <LoadingPage /> });
const ManageBookingsPage = loadable(() => import(/* webpackChunkName: "ManageBookingsPage" */ './containers/ManageBookingsPage/ManageBookingsPage'), { fallback: <LoadingPage /> });;
const VideoConferencePage = loadable(() => import(/* webpackChunkName: "VideoConferencePage" */ './containers/VideoConferencePage/VideoConferencePage'), { fallback: <LoadingPage /> });
const VRSSalesPage = loadable(() => import(/* webpackChunkName: "VRSSalesPage" */ /* webpackPrefetch: true */ './containers/VRSSalesPage/VRSSalesPage'), { fallback: <LoadingPage /> });
const ResourcesPage = loadable(() => import(/* webpackChunkName: "ResourcesPage" */ /* webpackPrefetch: true */ './containers/ResourcesPage/ResourcesPage'), { fallback: <LoadingPage /> });
const GetInvolvedPage = loadable(() => import(/* webpackChunkName: "GetInvolvedPage" */ /* webpackPrefetch: true */ './containers/GetInvolvedPage/GetInvolvedPage'), { fallback: <LoadingPage /> });

// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() => import(/* webpackChunkName: "StyleguidePage" */ './containers/StyleguidePage/StyleguidePage'), { fallback: <LoadingPage /> });
const ContractsPage = loadable(() => import(/* webpackChunkName: "ContractsPage" */ './containers/ContractsPage/ContractsPage'), { fallback: <LoadingPage /> });

const DeafUserVideoAccessPage = loadable(() => import(/* webpackChunkName: "DeafUserVideoAccessPage" */ './containers/DeafUserVideoAccessPage/DeafUserVideoAccessPage'), { fallback: <LoadingPage /> });


export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'AddressDetailsPage',
  'PasswordChangePage',
  'GPDetailsPage',
  //'StripePayoutPage',
  //'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

const RedirectToContractsInfoPage = () => <NamedRedirect name="ContractsPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage,
      //loadData: pageDataLoadingAPI.LandingPage.loadData,
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
    },
    { 
      path: '/p/:pageId',
      name: 'CMSPage',
      component: CMSPage,
      loadData: pageDataLoadingAPI.CMSPage.loadData,
    },
    {
      path: '/about-us.html',
       name: 'AboutPage',
        component: () =>
         (<NamedRedirect name="AboutPage" />)},
    {
    path: '/types-of-communication-professionals',
    name: 'CPTypesPage',
    component: CPTypesPage,
    },
    {
      path: '/contact',
      name: 'ContactPage',
      component: props => <ContactPage {...props} />,
      loadData: pageDataLoadingAPI.ContactPage.loadData,
    },
    {
      path: '/contracts-info',
      name: 'ContractsInfoPage',
      component: ContractsInfoPage,
      authPage: 'ContractsPage',
      auth: true

    },
    {
      path: '/contracts-list', 
      name: 'ContractsContentPage', 
      component: ContractsContentPage
    },
    {
      path: '/deaf-users',
      name: 'DeafPage',
      component: DeafPage,
    },
    {
      path: '/deaf-users-video',
      name: 'DeafUserVideoAccessPage',
      component: DeafUserVideoAccessPage,
      auth: true,
      authPage: 'LoginPage'
    }, 
    {
      path: '/get-involved',
      name: 'GetInvolvedPage',
      component: props => <GetInvolvedPage {...props} />,
    },
    {
      path: '/get-involved.html',
       name: 'GetInvolvedPage',
        component: props =>
         (<NamedRedirect name="GetInvolvedPage" params={{...props.params}} />)},
    {
      path: '/faq',
      name: 'FAQPage',
      component: FAQPage,
    },
    {
      path: '/faqs.html',
       name: 'FAQPage',
        component: () =>
         (<NamedRedirect name="FAQPage" />)
    },
    {
      path: '/favourites',
      name: 'FavouritesPage',
      component: props => <FavouritesPage {...props} />,
      loadData: pageDataLoadingAPI.FavouritesPage.loadData,
    },
    {
      path: '/help',
      name: 'HelpPage',
      component: HelpPage,
    },
    {
      path: '/join-us',
      name: 'JoinUsPage',
      component: props => <JoinUsPage {...props} />,
    },
    {
      path: '/join-us-communication-professionals',
      name: 'CommProfJoinPage',
      component: props => <CommProfJoinPage {...props} />,
    },
    {
      path: '/join-us-deaf-community',
      name: 'DeafCommJoinPage',
      component: props => <DeafCommJoinPage {...props} />,
    },
    {
      path: '/join-us-investors',
      name: 'InvestorsJoinPage',
      component: props => <InvestorsJoinPage {...props} />,
    },
    {
      path: '/knowsleyplace',
      name: 'KnowsleyPlacePage',
      component: RedirectToContractsInfoPage,
    },
    {
      path: '/liverpoolplace',
      name: 'LiverpoolPlacePage',
      component: RedirectToContractsInfoPage,
    },
    {
      path: '/resources',
      name: 'ResourcesPage',
      component: props => <ResourcesPage {...props} />,
    }, 
    {
      path: '/resources.html',
       name: 'ResourcesPage',
        component: props =>
         (<NamedRedirect name="ResourcesPage" params={{...props.params}}/>)
    },
    {
      path: '/signup-for-updates',
      name: 'MailchimpSignupPage',
      component: MailchimpSignupPage,
    },
    {
      path: '/video-info',
      name: "VideoAboutPage",
      component: VideoAboutPage
    },
    {
      path: '/video-interpreting-service',
      name: "VRSSalesPage",
      component: VRSSalesPage
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/donate.html',
      name: 'HomePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{
            slug: draftSlug,
            id: draftId,
            type: 'new',
            tab: 'description',
          }}
        />
      ),
    },
    {
      path: '/l/requirement',
      name: 'CreateRequirementPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{
            slug: draftSlug,
            id: draftId,
            type: 'new',
            tab: 'description',
            category: 'requirement',
          }}
        />
      ),
    },
   
    {
      path: '/l/:slug/:id/:type/:tab/:category?',
      name: 'EditListingPage',
      auth: true,
      // component: props => <EditListingPage {...props} />,
      // loadData: EditListingPage.loadData,
      component: EditListingPage,
      // extraProps: { allowOnlyOneListing: true },
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType/:category?',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/contracts',
      name: 'ContractsPage',
      component: ContractsPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: '/contractlogin',
      name: 'ContractLogin',
      component: ContractsPage,
      extraProps: { tab: 'contractLogin' },
    },
    {
      path: '/video-service',
      name: 'VideoConferencePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <VideoConferencePage {...props} />,
    },
    {
      path: '/video-service-success',
      name: 'VideoConferenceSuccessPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <VideoConferenceSuccessPage {...props} />,
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
      // loadData: pageDataLoadingAPI.AuthenticationPage.loadData, //TODO check after merge
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: props => <AuthenticationPage {...props} tab="confirm" />,
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
    },
    {
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: '/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ManageListingsPage {...props} />,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: '/bookings',
      name: 'ManageBookingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ManageBookingsPage {...props} />,
      loadData: pageDataLoadingAPI.ManageBookingsPage.loadData,
    },
    {
      path: '/bookings/draft',
      name: 'ManageDraftBookingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ManageBookingsPage {...props} currentTab='draft' />,
      loadData: pageDataLoadingAPI.ManageBookingsPage.loadData,
    },
    {
      path: '/book-bsl-interpreters.html',
      name: 'ManageBookingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ManageBookingsPage {...props} />,
      loadData: pageDataLoadingAPI.ManageBookingsPage.loadData,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: '/account/address-details',
      name: 'AddressDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <AddressDetailsPage {...props} />,
      loadData: pageDataLoadingAPI.AddressDetailsPage.loadData,
    },
    {
      path: '/account/gp-details',
      name: 'GPDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <GPDetailsPage {...props} />,
      loadData: pageDataLoadingAPI.GPDetailsPage.loadData,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage,
      //loadData: pageDataLoadingAPI.TermsOfServicePage.loadData,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
      //loadData: pageDataLoadingAPI.PrivacyPolicyPage.loadData,
    },
    {
      path: '/privacy-policy.html',
       name: 'PrivacyPolicyPage',
        component: () =>
         (<NamedRedirect name="PrivacyPolicyPage"/>)},  

    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage ,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
    // Do not change this path!
    //
    // The API expects that the application implements /preview endpoint
    {
      path: '/preview',
      name: 'PreviewResolverPage',
      component: PreviewResolverPage ,
    },
  ];
};

export default routeConfiguration;
